@font-face {
    font-family: 'anticon';
    src: url('https://at.alicdn.com/t/font_r5u29ls31bgldi.eot');
    /* IE9*/
    src: url('https://at.alicdn.com/t/font_r5u29ls31bgldi.eot?#iefix') format('embedded-opentype'), /* chrome、firefox */ url('https://at.alicdn.com/t/font_r5u29ls31bgldi.woff') format('woff'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('https://at.alicdn.com/t/font_r5u29ls31bgldi.ttf') format('truetype'), /* iOS 4.1- */ url('https://at.alicdn.com/t/font_r5u29ls31bgldi.svg#iconfont') format('svg');
  }
  
  
  @media(max-width: 568px) {
      body.pace-running {
          background-repeat: no-repeat !important;  
          background-size: 120px 35px !important;
          position: absolute;
          left: 0;
          top: 0px;
          margin-left: auto !important;
      }
  }
  /* latin-ext */
  @font-face {
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 300;
      src: local('Oxygen Light'), local('Oxygen-Light'), url(https://fonts.gstatic.com/s/oxygen/v7/2sDcZG1Wl4LcnbuCJW8zZmW5Kb8VZBHR.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 300;
      src: local('Oxygen Light'), local('Oxygen-Light'), url(https://fonts.gstatic.com/s/oxygen/v7/2sDcZG1Wl4LcnbuCJW8zaGW5Kb8VZA.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 400;
      src: local('Oxygen Regular'), local('Oxygen-Regular'), url(https://fonts.gstatic.com/s/oxygen/v7/2sDfZG1Wl4LcnbuKgE0mRUe0A4Uc.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 400;
      src: local('Oxygen Regular'), local('Oxygen-Regular'), url(https://fonts.gstatic.com/s/oxygen/v7/2sDfZG1Wl4LcnbuKjk0mRUe0Aw.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 700;
      src: local('Oxygen Bold'), local('Oxygen-Bold'), url(https://fonts.gstatic.com/s/oxygen/v7/2sDcZG1Wl4LcnbuCNWgzZmW5Kb8VZBHR.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 700;
      src: local('Oxygen Bold'), local('Oxygen-Bold'), url(https://fonts.gstatic.com/s/oxygen/v7/2sDcZG1Wl4LcnbuCNWgzaGW5Kb8VZA.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
  
    html *
  {
     font-family: 'Oxygen' !important;
  }
  
  ::-webkit-scrollbar { 
      display: none; 
  }
  
  button:focus {outline:0;}
  .btn:focus{outline: 0 !important}
  
  html,
  body {
      overflow: hidden;
      background-color: #fff !important;
  }
  
  .ant-time-picker-panel {
      zoom: 100% !important;
      @media (min-width: 320px) and (max-width: 480px) {
          zoom: 60% !important;
        }
  }

  .ant-time-picker-panel-combobox {
    zoom: 90% !important;
  }
  
  #sidebar,
  #root {
      font-size: 14px !important;
      @media (min-width: 320px) and (max-width: 480px) {
          zoom: 60%;
        }
        @media only screen and (max-width: 600px) {
            zoom: 100%;
          }
    
  }
  
  #sidebar {
      @media (max-width:667px)  {
          zoom: 60%;
          width: 350px !important;
        }
  }
  
  .logs-container {
      @media (max-width:667px)  {
          zoom: 60%;
          margin-top: 80px !important;
      }
  }
  
  .logs-main-container {
      @media (max-width:667px)  {
          margin-top: 0px !important;
      }
  }
  
  .sidebar-header-custom {
      margin: 15px;
      padding-top: 10px !important;
      padding-bottom: 0px !important;
      color: #89949B !important;
      text-transform: uppercase;
  }
  .sidebar-separator {
      margin: 10px;
      border: 1.5px solid #eeeeee;
      background-color: #eee;
  }
  
  .DateRangePicker_picker__directionLeft {
      z-index: 10000 !important;
      left: 0px !important;
      zoom: 80% !important;
      @media (min-width: 320px) and (max-width: 480px) {
          zoom: 60% !important;
        }
  }
  
  .container-fluid {
      -webkit-overflow-scrolling: auto !important;
  }
  
  .sidebar-header-custom {
      margin: 15px;
      padding-top: 10px !important;
      padding-bottom: 0px !important;
      color: #89949B !important;
      text-transform: uppercase;
  }
  .sidebar-separator {
      margin: 10px;
      background-color: #eee;
      border: 1.5px solid #eeeeee;
  }
  
  .navbar-nav .open .dropdown-menu {
      max-height: 400px;
      overflow: scroll;
      @media (max-width: 989px) {
          position: absolute;
          top: 80px;
          left: -250px;
          background-color: white;
      }
      @media (max-width: 568px) {
          top: 70px;
          left: -100px;
      }
  }
  
  
  .header-menu>.dropdown-menu {
      overflow: scroll !important;
  }
  
  #rubix-nav-header {
      zoom: 80%;
      @media (min-width: 320px) and (max-width: 480px) {
          zoom: 60%;
      }
      @media (max-width:667px)  {
          zoom: 60%;
        }    
  }
  
  .details-section {
      position: fixed;
      top: 135px;
      height: calc(100% - 135px);
      padding-top: 20px;
      padding-right: 25px;
      padding-left: 20px;
      width: calc((40/100)*(100% - 200px));
      @media (max-width: 989px) {
          width: calc((40/100)*(100% - 25px));
      }
      @media (max-width: 568px) {
          width: calc(100% - 25px);
      }
      @media (max-width:667px)  {
          zoom: 60%;
          top: 160px;
        }
  }
  
  .log-section {
      position: fixed;
      top: 140px;
      height: calc(100% - 140px);
      padding-top: 20px;
      width: calc(100% - 300px);
      @media (max-width: 768px) {
          margin-left: -22px;
          width: calc(100% - 3px);
          overflow-x: hidden;
      }
  }
  
  .operational-section {
    width: 67%;
  }
  
  .form-control{
      border-radius: 3px !important;
      min-height: 36px !important;
      border-width: 0px !important;
  }
  
  .form-control:focus {
      border-width: 1px !important;
      border-color: #80bdff !important;
  }
  
  .has-success .form-control:focus {
      border-width: 1px !important;
      border-color: #80bdff !important;
  }
  
  .has-error .form-control {
      border-width: 1px !important;
      border-color: #D32F2F !important;
  }
  
  .has-error .form-control:focus {
      border-width: 1px !important;
      border-color: #D32F2F !important;
  }
  .form-control[disabled], fieldset[disabled] .form-control {
      border-radius: 5px !important;
      border: 0px !important;
  }
  
  .form-control{
      border-radius: 3px !important;
      min-height: 36px !important;
      border-width: 0px !important;
  }
  
  .form-control:focus {
      border-width: 1px !important;
      border-color: #80bdff !important;
  }
  
  .has-success .form-control:focus {
      border-width: 1px !important;
      border-color: #80bdff !important;
  }
  
  .form-control-custom {
      border-width: 1px !important;
      border-radius: 3px !important;
      border-width: 0px 0px 2px 0px;
  }
  
  .form-control-noBorder {
      border-width: 0px !important;
  }
  
  .form-control-noBorder:focus {
      border-width: 0px !important;
  }
  
  .form-control-select {
      height: 37px !important;
      border-width: 1px !important;
      border-radius: 3px !important;
      border-width: 0px 0px 2px 0px;
  }
  
  .has-success .form-control {
      border-width: 1px !important;
      border-color: #ddd !important;
  }
  
  .form-control[disabled], fieldset[disabled] .form-control {
      border-radius: 5px !important;
      border: 0px !important;
  }
  
  .login-input {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-color: #eeeeee !important; 
  }
  
  .login-input-icon {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
  }
  .link-label {
      color: rgb(27, 151, 194);
  }
  .link-label:hover {
      text-decoration: underline;
      cursor: pointer;
  }
  
  
  .rounded-view {
      height: 70px;
      width: 70px;
      min-width: 70px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  input[type="file"] {
      line-height: 16px;
  }
  
  .logs-list {
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 15px;
      background-color: white;
  }
  
  .row-hightlight:hover {
      background-color: #c4c4c4;
      color: white;
  }
  
  .separator {
      border-top:1.5px solid rgb(189, 189, 189);
      margin: 5px;
  }
  .tag-element-selected {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
      -o-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
  }
  
  .shadowed {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  }
  
  .tag {
      border-radius: 3px;
      display: inline-block;
      position: relative;
      padding: 1px 5px 0px 5px;
      vertical-align: middle;
      color: white;
      margin-right: 10px;
      margin-left: 5px;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -2px rgba(0, 0, 0, 0.12)
  }
  
  @media (max-width: 568px) {
      .rounded-view {
          height: 70px !important;
          width: 70px !important;
          min-width: 70px !important;
      }
  }
  
  .remote-open-loading-container {
    margin: 50px;
    margin-bottom: 80px;
    margin-top: 80px; 
    margin-right: 80px;
    @media (max-width: 568px) {
      margin-right: 30px;
      margin-left: 25px;
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
  
  .sidebar-row {
      margin-left: 5px;
      margin-right: 10px;
      border-radius: 5px;
      border-left: 4px solid transparent;
      background-color: transparent;
  }
  
  .sidebar-row:hover {
      margin-left: 5px;
      margin-right: 10px;
      border-radius: 5px;
      border-left: 4px solid #0767DB;
  }
  
  .sidebar-row-active {
      background-color: #e3edfb;
      border-left: 4px solid #0767DB;
  }
  .sidebar-row-text {
      font-size: 15px;
      font-weight: 500;
      color: #66788A;
  }
  
  .sidebar-row-text:hover {
      color: #0767DB;
  }
  
  .sidebar-row-text-active {
      font-weight: bold;
      color: #0767DB;
  }
  .sidebar-row:hover .sidebar-row-icon {
      color: #0767DB !important;
  }
  
  .sidebar-row:hover .sidebar-row-text {
      color: #0767DB !important;
  }
  
  .sidebar-row-icon {
      color: #66788A !important;
      min-width: 40px !important;
  }
  
  .sidebar-row-icon-active {
      color: #0767DB !important;
      min-width: 40px !important;
  }
  
  .open-remote-icon {
    color: #3f3f3f; 
    font-size: 30px;
  }
  .open-remote-text {
    margin-top: 20px; 
    color: #3f3f3f;
    @media (max-width: 568px) {
      font-size: 10px;
    }
  }
  
  .pincode-input-text {
    @media (max-width: 568px) {
      height: 35px !important;
      width: 35px !important;
    }
  }
  
  .footer-terms-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      position: fixed;
      bottom: 5px;
      right: 20px;
      @media only screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
      }
  }
  
  
  .footer-logo-container {
      display: flex;
      justify-content: center;
      flex-direction: colum;
      position: fixed;
      bottom: 5px;
      left: 10px;
  }
  
  /* MULTIPLE SELECT STYLE */
  .Select-control {
      height: 37px !important;
      border-radius: 5px !important;
      border: 2px solid #d2d2d2 !important;
  }

  .Select-placeholder, .Select--single > .Select-control .Select-value {
    padding-left: 0px !important;
  }

  .Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
    padding-right: 0px !important;
  }
  
  .Select-placeholder {
      padding-top: 0px;
      padding-left: 13px !important;
      line-height: 25px;
      margin-top: 0px;
      font-size: 14px;
      color: #9d9d9d;
  }
  
  .Select .Select-input {
      height: 27px !important;
      font-size: 14px;
  }
  
  .Select-menu-outer {
      z-index: 10000000;
      overflow: visible;
      font-size: 14px;
  }
  
  
  /* MULTIPLE SELECT*/
  .Select--single .Select-control .Select-value {
      border-radius: 2px;
      border: 2px solid rgba(0, 126, 255, 0.24);
      display: flex;
      font-size: 14px;
      line-height: 1.4;
      margin-left: 5px;
      margin-top: 3px;
      vertical-align: top;
      width: fit-content;
      align-items: center;
      margin-bottom: 2px;
      padding-left: 0px;
      padding-right: 0px;
  }
  
  .Select--multi .Select-value {
      margin-bottom: 4px;
      font-size: 14px !important;
      border-width: 2px !important;
  }
  
  .Select--single .Select-value-icon {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      border-right: 1px solid #c2e0ff;
      border-right: 1px solid rgba(0, 126, 255, 0.24);
      padding: 1px 5px 3px;
  }
  .Select--single .Select-value-label {
      display: inline-block;
      vertical-align: middle;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      cursor: default;
      padding: 5px 5px;
  }
  
  
  
  .button-label-responsive {
      @media (max-width: 1560px) {
          display: none;
      }
  }
  
  ul.react-list-select {
      list-style: none;
      padding: 0;
      margin: 0;
      background: #fafafa;
      border: 1px solid #cacaca;
      margin-right: .5em;
      outline: none;
  }
  
  .react-list-select--item {
      padding: 0.3em 0.6em;
      cursor: pointer;
      position: relative;
      border-bottom: solid 1px grey;
  
      &.is-selected {
        background: #D7E7FF;
      }
  
      &.is-focused {
        background: #f1f1f1;
        &.is-selected {
          background: #D7E7FF;
        }
      }
  }
  
  .flex-wrap-space-between {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  
  .flex-column-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  
  .no-entity {
      margin-top: 160px;
  }
  
  .tabs-container-fixed {
      position: fixed;
      width: calc((0.6 * (100% - 250px)) - 75px);
      @media (max-width: 989px) {
          width: 60%;
          padding-right: 35px;
      }
      @media (max-width: 568px) {
          width: 100%;
          padding-right: 20px;
      }
  }
  
  .tab-content {
      height: 100vh;
      overflow: scroll;
  }
  
  .drop-file-container {
      width: 100%;
      background-color: #dedede80;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 75px;
      border: 2px dashed #d5d5d5;
      color: #3f3f3f;
    }
    
    .drop-file-container-active {
      background-color: #A5D6A7;
      border: 0px dashed #d5d5d5;
      color: #3f3f3f;
    }
  
    .drop-file-container:hover {
        cursor: pointer;
    }
    
    
  .smart-link:hover {
      text-decoration: underline;
  }
  
  .submit-button {
      position: absolute;
      left: 0;
      right: 0; 
      bottom: 0;
      width: 100%;
      margin: 0px;
  }
  
  .selectable-row:hover {
      background-color: #F5F5F5 !important;
      cursor: pointer;
  }
  
  .modal-content {
      zoom: 80% !important;
      @media (min-width: 320px) and (max-width: 480px) {
          zoom: 60% !important;
        }
        @media only screen and (max-width: 600px) {
            zoom: 80% !important;
          }
    }
  
  
  .modal-content-transparent  .modal-content {
      background-color: transparent !important;
      box-shadow: none !important;
      border: none;
  }
  .credential-status-triangle-container {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 60px 60px;
      border-color: transparent transparent #007bff transparent;
  }
  
  .snack-title {
      margin: 5px;
      @media only screen and (max-width: 600px) {
        font-size: 25px;
      }
  }
  
  
  #plant-menu-btn {
      color: white !important;
      font-size: 18px !important;
      line-height: unset !important;
      font-weight: bold;
  }
  
  .full-height-modal {
      height: 100% !important;
      background-color: red;
  }
  
  ::marker {
      color: transparent; //remove • from export logs menu
  }
  
  .csvDownloadMenuItem {
      color: #89949b;
  }
  
  .__react_component_tooltip.place-top {
      zoom: 125% !important;
      font-size: 10px !important;
  }
  
  .location-menu {
      max-height: 400px;
      overflow: scroll;
  }
  
  .MuiCollapse-entered {
      overflow: visible !important;
  }

  .MuiSlider-root {
    zoom: 120%;
  }

  .MuiSlider-markLabel {
    font-size: 10px !important;
  }
  
  .MuiSlider-markLabelActive {
    font-size: 10px !important;
  }
  .sentence-input {
      border-width: 2px !important;
  }
  
  #avatar {
      -webkit-box-shadow: 0px 2px 4px 0px #000000;
      box-shadow: 0px 2px 4px 0px #000000;
  }
  
  .box-shadow-bottom {
      box-shadow: -5px 12px 6px -8px rgba(0,0,0,0.52);
      -webkit-box-shadow: -5px 12px 6px -8px rgba(0,0,0,0.52);
      -moz-box-shadow: -5px 12px 6px -8px rgba(0,0,0,0.52);
  }
  
  .hidden-input {
      display: none;
  }
  
  @media (min-width: 990px) {
      .modal-dialog {
          width: 990px !important
      };
  }
  
  .large-modal .modal-dialog {
      width: 900px !important
  };
  
  @media screen and (min-width: 1600px) {
      .large-modal .modal-dialog {
          width: 1200px !important
      };
  }
  
  
  
  .input-inline:hover {
      cursor: pointer;
  }
  
  @media screen and (min-width: 1600px) {
      .storeContentContainer {
          max-height: 600px !important;
      }
  }
  
  .storeContentContainer p {
      color: #3f3f3f;
      font-size: 20px;
      line-height: 1.5;
  }

  .storeCardContentContainer p {
    color: #3f3f3f;
      font-size: 17px;
      line-height: 1.5;
  }
  
  .storeContentContainer h2 {
      color: #3f3f3f;
      font-size: 22px;
      font-weight: bold;
  }
  
  .storeContentContainer h3 {
      color: #3f3f3f;
      font-size: 22px;
      font-weight: bold;
  }
  
  .storeContentContainer h4 {
      color: #3f3f3f;
      font-size: 22px;
      margin-bottom: 5px;
      margin-top: 20px;
  }
  
  .storeContentContainer figure {
      .aligncenter {
          display: flex;
          align-items: center;
          flex-direction: column;
      }
  }
  
  .storeContentContainer ul {
      list-style: disc outside;
      margin-top: 0.5em;
  }
  
  .storeContentContainer ::marker {
      color: #3f3f3f;
  }
  
  .storeContentContainer li {
      display: list-item;
      text-align: -webkit-match-parent;
      color: #3f3f3f;
      font-size: 20px;
      margin-top: 10px;
  }
  
  .storeContentContainer .wp-block-image .aligncenter {
      margin-left: auto;
      margin-right: auto;
      display: table;
      margin-top: 20px;
  }
  
  .storeContentContainer .wp-block-image .aligncenter>figcaption {
      display: table-caption;
      caption-side: bottom;
  }
  
  .storeContentContainer figure figcaption {
      font-style: italic;
      color: #3f3f3f;
      font-size: 16px;
      margin-top: 0.5em;
      margin-bottom: 1em;
  }
  
  .storeContentContainer figure {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  
  .storeContentContainer .wp-block-cover  {
      position: relative;
      background-position: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em;
      box-sizing: border-box;
      padding-left: 25px;
  }
  
  .storeContentContainer .wp-block-cover .has-background-dim:not([class*=-background-color]) {
      background-color: #000;
  }

  
  
  .storeContentContainer .wp-block-cover .wp-block-cover__background {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      opacity: .5;
  }
  
  .storeContentContainer .wp-block-cover .wp-block-cover__image-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      object-fit: cover;
      outline: none;
      border: none;
      box-shadow: none;
  }
  
  .storeContentContainer .wp-block-cover .wp-block-cover__inner-container {
      text-align: left;
      color:white;
      text-align: left;
      margin-left: 0px;
      margin-right: 0;
      width: 100%;
      z-index: 1;
  }
  
  
  
  .storeContentContainer .wp-block-cover .wp-block-cover__inner-container h1{
      margin-top: -20px;
      margin-left: 20px;
  }
  
  
  .storeContentContainer .wp-block-cover .wp-block-cover__inner-container h2{
      margin-top: 0px;
      margin-left: 20px;
  }
  
  
  .storeContentContainer .wp-block-cover p:not(.has-text-color) {
      color: inherit;
      margin-left: 20px;
  }
  
  .storeContentContainer .wp-block-cover h2:not(.has-text-color) {
      color: inherit;
  }
  
  .storeContentContainer .editor-styles-wrapper .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
      margin-left: auto !important;
      margin-right: auto !important;
      max-width: 650px;
  }

  .wp-block-columns {
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-around;
  }
  
  .wp-block-cover__image-background { // login image
    width: 100% !important;
    height: 200px !important;
    background-size: cover !important;
    background-position: center !important;
  }
  
  .filter-row {
      margin-left: 5px;
      margin-right: 10px;
      border-radius: 5px;
      border-left: 4px solid transparent;
      background-color: transparent;
  }
  
  .filter-row:hover {
      margin-left: 5px;
      margin-right: 10px;
      border-radius: 5px;
      border-left: 4px solid #0767DB;
  }
  
  .filter-row-active {
      background-color: #e3edfb;
      border-left: 4px solid #0767DB;
  }
  .filter-row-text {
      font-size: 12px;
      font-weight: 500;
      color: #66788A;
  }
  
  .filter-row-text:hover {
      color: #80868d;
  }
  
  .filter-row-text-active {
      font-weight: bold;
      color: #0767DB;
  }
  .filter-row:hover .filter-row-icon {
      color: #0767DB !important;
  }
  
  .filter-row:hover .filter-row-text {
      color: #0767DB !important;
  }
  
  .filter-row-icon {
      color: #66788A !important;
      min-width: 40px !important;
  }
  
  .filter-row-icon-active {
      color: #0767DB !important;
      min-width: 40px !important; 
  }
  
  .form-control-rounded {
      border-width: 2px !important;
      border-radius: 30px !important;
      border-width: 0px 0px 2px 0px;
  }
  
  .wp-block-video video{
      height: 350px;
      width: 65%;
  }
  
  // .wp-block-video video::-internal-media-controls-download-button {
  //     display:none;
  //    }
  
  //    .wp-block-video video::-webkit-media-controls-enclosure {
  //         overflow:hidden;
  //    }
  
  //    .wp-block-video video::-webkit-media-controls-panel {
  //         width: calc(100% + 30px); 
  //    }

  .custom-checkbox-root .MuiSvgIcon-root{
    width: 24px;
    height: 24px;
    color: #1B97C2;
  }
  
#dropCanvas {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.floorMapCanvasContainer {
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.floorMapCanvasContainer::-webkit-scrollbar {
    display: unset !important;
    width: 12px !important;
    height: 12px !important;
}

.floorMapCanvasContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.floorMapCanvasContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.MuiExpansionPanelSummary-content {
    margin: 0px !important
}

._lr-hide {
    border: 2px solid transparent !important;
    border-radius: 2px !important;
}

.form-control:focus {
    border: 2px solid #0767DB !important;
    border-radius: 2px !important;
}

.form-control-custom {
    border: 2px solid #d2d2d2 !important;
    border-radius: 2px !important;
    margin-top: 5px !important;
}
.form-control-select {
    border: 2px solid #d2d2d2 !important;
    border-radius: 5px !important;
}

.form-control-select.form-control:focus {
    border-radius: 5px !important;
}
.ant-time-picker-input {
    border: 2px solid #d2d2d2 !important;
    border-radius: 2px !important;
}

